import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Viewer from "react-viewer";

// Mock item data
const items = [
  {
    id: 1,
    title: "પ્રાપ્તિ૨૦૨૪",
    description: "First Patra Prapti no patra",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
  {
    id: 2,
    title: "Item 2",
    description: "This is the second item's description.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/subscription-82909.appspot.com/o/IMG_2696.JPG?alt=media",
    ],
  },
  // Add more items...
];

const ItemDetail = () => {
  const { title } = useParams(); // Fetch the item title from the route
  
  // Find item based on the title
  const item = items.find((item) => item.title === title); 

  // Call hooks before conditional return
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Swipeable handlers for image navigation
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % item.images.length),
    onSwipedRight: () => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + item.images.length) % item.images.length),
  });

  // If item does not exist, display "Item not found" message
  if (!item) {
    return <div>Item not found</div>;
  }

  const handleItemClick = () => {
    setIsViewerOpen(true); // Open the viewer on item click
  };

  const handleBack = () => {
    setIsViewerOpen(false); // Close the viewer
  };

  return (
    <div>
      <h2>{item.title}</h2>
      <p>{item.description}</p>
      <button onClick={handleItemClick}>View Images</button>
      
      {/* The image viewer */}
      <div {...swipeHandlers}>
        <Viewer
          visible={isViewerOpen}
          onClose={handleBack}
          images={item.images.map((src) => ({ src }))}
          activeIndex={currentImageIndex}
          zoomable={true} // Enable zooming
          rotatable={false}
          downloadable={false}
          attribute={false}
          scalable={false}
          disableMouseZoom
        />
      </div>
    </div>
  );
};

export default ItemDetail;
